<template>
    <SmartForm base_url="/circular/state" base_path="/circular/state" form_type="view"></SmartForm>
</template>

<script>
import SmartForm from "@/view/components/SmartForm.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
    components: {
        SmartForm,
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Comunicazioni", route: "/circular" },
            { title: "Stati", route: "/circular/state/index" },
            { title: "Dettagli Stato" }
        ]);
    }
};
</script>
